import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../../../../components/Layouts/layout';
import SEO from '../../../../components/seo';
import Alert from '../../../../components/Alert/Alert';

const ReserachEdPage = () => {
	const intl = useIntl();
	return (
		<Layout
			breadcrumb={{
				text: 'Technical Research & Education: Resource Hub',
				path: '/about/research-and-education-program/technical-research-and-education-resource-hub/'
			}}
			header={<span className='bold'>2024 Ontario Building Code Updates</span>}
		>
			<SEO lang={intl.locale} title='2024 Ontario Building Code Updates' />
			<Container>
				{intl.locale === 'fr' && (
					<Row align='stretch'>
						<Col>
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						</Col>
					</Row>
				)}
				<Row className=''>
					<Col>
						<p>
							With the Ontario government releasing over 1,730 updates to the Ontario Building Code (OBC)
							to increase harmonization with the National Construction Codes, the HCRA is committed to
							supporting your educational needs as you prepare for this transition. As you may be aware,
							we have launched a Research & Education program for this very purpose: to provide you with
							value-added voluntary educational resources and technical research as new construction
							trends emerge, and Building Code standards evolve.
						</p>
						<p>
							This Resource Hub will be your dedicated source for updated training, educational resources,
							and information on current and emerging trends, including requirements on the new Code
							updates. <u>We recommend you bookmark this site for future use</u>.
						</p>
						<h3>
							Webinar:{' '}
							<a href='https://www.youtube.com/watch?v=r0jqZjshnLI&feature=youtu.be'>
								2024 Ontario Building Code: What’s In and What’s Out
							</a>
						</h3>
						<p>
							The HCRA partnered with key stakeholders to deliver a free webinar of the most impactful
							changes, new provisions, and key transition dates. The webinar was recorded and is available
							for viewing{' '}
							<a
								aria-label='Recording of 2024 Ontario Building Code: What’s In and What’s Out webinar'
								href='https://www.youtube.com/watch?v=r0jqZjshnLI&feature=youtu.be'
							>
								online
							</a>
							.
						</p>

						<h3>Highlights: Updates to the Ontario Building Code:</h3>
						<ul>
							<li>
								Ontario's two-unit house provisions have harmonized with the National Building Code's
								requirements for <b>secondary suites</b>, except for the National Building Code's
								limitations around minimum suite sizes. Ontario will maintain its flexibility on suite
								sizes to support the government's housing objectives and minimize costs.
							</li>
							<li>
								Ontario has harmonized with the National Building Code on mitigating the effects of{' '}
								<b>radon</b> on buildings across Ontario. The approach being adopted to address soil
								gas/radon will require new houses to have a rough-in for a subfloor depressurization
								system if subsequently required. The changes will include corresponding revisions to
								reference Supplementary Standard SB-9 and a new Appendix Note to clarify that buildings
								occupied for a few hours a day may not be required to implement soil gas protection
								methods.
							</li>
							<li>
								Ontario will continue to operate under the SB12 & SB10 energy performance guidance and
								efficiency levels. Any harmonization with the NBC 9.36 2020 Tier 3 and Tier 1 is not
								adopted at this time.
							</li>
							<li>
								There have been changes to Building Code Applicable Law - Environmental Assessment Act
								and Conservation Authorities Act:{' '}
								<a href='http://www.ontario.ca/laws/regulation/r24073'>
									www.ontario.ca/laws/regulation/r24073
								</a>{' '}
								and{' '}
								<a href='http://www.ontario.ca/laws/regulation/r24158'>
									www.ontario.ca/laws/regulation/r24158
								</a>
								.
							</li>
						</ul>
						<h3>Additional Resources:</h3>
						<ul>
							<li>
								<a href='https://www.ohba.ca/member-updates-sample/'>
									Ontario Home Builders’ Association: Summary of 2024 OBC Updates
								</a>
							</li>
							<li>
								<a href='https://www.oboa.on.ca/wp-content/uploads/2020/03/Bill-23-Legislation-Aird-Berlis.pdf'>
									A legal perspective on Bill 23 from Aird Berlis (PDF download)
								</a>
							</li>
							<li>
								<a href='https://www.ontario.ca/document/changes-2012-building-code/stairs-guards-and-handrails'>
									2019 Building Code Amendments related to Stairs, Guards and Handrails
								</a>
								: Reminder that amendments related to stairs, guards and handrails came into effect on{' '}
								<b>January 1, 2022</b>. They were part of 2019 changes to the Building Code to further
								harmonize Ontario’s Building Code with the 2015 National Codes. The HCRA is hearing
								about designs being submitted to pre-2019 standards.
							</li>
						</ul>
						<p>
							<b>Now available:</b> the digital <b>2024 Building Code Compendium</b>. Request your{' '}
							<a href='https://www.ontario.ca/page/2024-ontario-building-code'>free digital copy</a>{' '}
							today.
							<br />
							<br />
							The full-length version of the Ontario Building Code will no longer be available on E-Laws.
							<br />
							<br />
							Links to the 2020 National Building Code and the Ontario Amendment document that constitutes
							the entire new 2024 Ontario's Building Code can be found as follows:
						</p>
						<ul>
							<li>
								<a href='https://www.ontario.ca/laws/regulation/r24163'>
									The Building Code Regulation on E-laws
								</a>
							</li>
							<li>
								<a href='https://www.dropbox.com/scl/fi/reo1c2ea44q0lzgexkyw2/Ontario-Amendments-to-the-National-Building-Code-of-Canada-April-5-2024.pdf?rlkey=ukw3vhci1nm1aa589psn8e5bo&e=1&dl=0'>
									The Ontario Amendment document
								</a>
							</li>
							<li>
								<a href='https://nrc-publications.canada.ca/eng/view/object/?id=515340b5-f4e0-4798-be69-692e4ec423e8'>
									The National Building Code
								</a>
							</li>
						</ul>
					</Col>
				</Row>
				<Row className='pageRow'>
					<Col>
						<h2>Transition Timelines:</h2>
						<p>Please take careful note of the following dates in the transition timeline:</p>
						<ul>
							<li>
								<b>April 10, 2024</b>: Release and filing of the 2024 Ontario Building Code.
							</li>
							<li>
								<b>January 1, 2025</b>: 2024 Ontario Building Code in effect.
							</li>
							<li>
								<b>March 31, 2025</b>: Deadline for permit applications using the 2012 Ontario Building
								Code, where working drawings were substantially complete before January 1, 2025.
							</li>
							<li>
								<b>April 1, 2025</b>: All permit applications must use the 2024 Ontario Building Code.
							</li>
						</ul>
						<h3>Key Transition Period Dates (December 31, 2024 - March 31, 2025):</h3>
						<div className='overflowContainer'>
							<table>
								<thead>
									<tr>
										<th className='borderRight'>April 10, 2024 - December 31, 2024</th>
										<th>January 1, 2025</th>
										<th>March 31, 2025</th>
										<th>April 1, 2025</th>
									</tr>
									<tr>
										<th className='borderRight' style={{ textAlign: `center` }}>
											9 Months
										</th>
										<th colSpan={3} style={{ textAlign: `center` }}>
											3 Months
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ minWidth: `12rem` }} className='borderRight'>
											Permit applications must be submitted using 2012 Building Code until
											December 31, 2024.
										</td>
										<td style={{ minWidth: `12rem` }}>
											Permit applicants may use the new 2024 building code.
										</td>
										<td style={{ minWidth: `15rem` }}>
											Last day to submit permit applications using the 2012 Building Code where
											working drawings were substantially complete before January 1, 2025.
										</td>
										<td style={{ minWidth: `12rem` }}>
											All permit applications must be submitted using the 2024 Bulding Code.
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default ReserachEdPage;
